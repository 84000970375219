<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col :cols="12" md="4" class="pb-0">
          <v-text-field v-model="name" label="Name for Line Item" outlined @change="(name) => $emit('update', { name })"></v-text-field>
        </v-col>
        <v-col :cols="12" md="4" class="pb-0">
          <v-text-field v-model="amount" label="Amount" type="number" outlined @change="(amount) => $emit('update', { amount })">
            <template v-slot:prepend-inner>
              <span style="margin-top:2px">$</span>
            </template>
          </v-text-field>
        </v-col>
        <v-col v-if="numericFields.length > 0" :cols="12" md="4" class="pb-0">
          <v-select v-model="field" :items="numericFields" label="Form Field" outlined @change="(field) => $emit('update', { field })"></v-select>
        </v-col>
      </v-row>
      <v-alert v-if="conditions.length === 0" type="info" outlined>This payment rule has no conditions, so will always be applied.</v-alert>
      <template v-else>
        <payment-rule-condition ref="conditionRef" v-for="(condition, index) in conditions" :key="'cond-' + index" :condition="condition" :inputs="conditionFields" :index="index" @update="(upd) => updateCondition(upd, index)" @remove="conditions.splice(index, 1)"></payment-rule-condition>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" text @click="conditions.push({ field: '', value: null })">Add Condition</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="error" text @click="$emit('remove')">Remove Rule</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { computed, ref } from '@vue/composition-api'

export default {
  props: {
    rule: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    inputs: {
      type: Array,
      required: true
    }
  },
  components: {
    PaymentRuleCondition: () => import('./PaymentRuleCondition')
  },
  setup (props, { emit }) {
    const name = ref(props.rule.name || '')
    const amount = ref(props.rule.amount || 0)
    const field = ref(props.rule.field || null)
    const numericFields = computed(() => {
      const arr = props.inputs.filter(({ input }) => input === 'number' || input === 'repeatable-block').map(({ label }) => { return { text: label, value: label } })
      if (amount.value !== 0) arr.unshift({ text: 'Flat Amount (not multiplied by field)', value: null })
      return arr
    })

    const conditionFields = computed(() => props.inputs.filter(({ input }) => input === 'number' || input === 'select' || input === 'combobox' || input === 'autocomplete' || input === 'radio' || input === 'checkbox' || input === 'switch'))
    const conditionRef = ref([])
    const conditions = ref(props.rule.cond || [])
    function updateCondition (upd, index) {
      const temp = { ...conditions.value[index], ...upd }
      // console.log(conditions.value[index])
      // console.log(upd)
      // console.log(temp)
      conditions.value.splice(index, 1, temp)
      // console.log(conditions.value)
      emit('update', { cond: conditions.value })
    }

    function validate () {
      if (name.value === '' || (amount.value === 0 && field.value == null)) return false
      let valid = true
      for (let i = 0; i < conditionRef.value.length; i++) {
        if (!conditionRef.value[i].validate()) valid = false
      }
      return valid
    }

    return {
      name,
      amount,
      field,
      numericFields,
      conditionFields,
      conditionRef,
      conditions,
      updateCondition,
      validate
    }
  }
}
</script>
